<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Pedidos
            </div>

            <v-form :disabled="loading">
                <v-row>
                    <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                        <DateFind label="Desde" v-model="filtros.FechaDesde"></DateFind>
                    </v-col>

                    <v-col class="mb-n7" cols="6" sm="5" md="3" lg="3" xl="2">
                        <DateFind label="Hasta" v-model="filtros.FechaHasta"></DateFind>
                    </v-col>

                    <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                        <PerFind class="mb-n7" ref="perCtrl" v-show="false" v-model="filtros.Per" label="Cliente" :perCatIdAnyLevel="102"></PerFind>
                        <!-- :perCatIdAnyLevel="perCatIdEmpleados" perCatIdEmpleados = 101 -->
                    </v-col>
                </v-row>
            </v-form>

            <v-btn
            to="/pedido-venta-nuevo"
            color="primary"
            class="mt-5 mb-3">
            Nuevo
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarPedido"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense
                >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.DocFec="{ item }">
                    {{ formatDate(item.DocFec) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.FecFin="{ item }">
                    {{ formatDate(item.FecFin) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Enviado="{ item }">
                  <v-simple-checkbox v-model="item.Enviado" disabled></v-simple-checkbox>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import DateFind from "../components/DateFind.vue";
import PerFind from "../components/PerFind.vue";
import { mapState } from "vuex";
export default ({
    components: {
        DateFind, PerFind
    },
    computed: {
        ...mapState(['empId', 'dirId', 'perId', 'urlRaiz', 'per'])
    },
    data () {
        return {
            loading: true,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Fecha pedido', width: 50, sortable: false, value: 'DocFec' },
                { text: 'Fecha entrega', width: 50, sortable: false, value: 'FecFin' },
                { text: 'Cliente', width:300, sortable: false, value: 'Per.PerNom' },
                { text: 'Enviado', width: 100, sortable: false, value: 'Enviado' },

            ],
            dtOptionsLoaded: false,
            filtros: {
                FechaDesde: null,
                FechaHasta: null,
                Per: {}
            }
        }
    },
    watch: {
      options: {
        handler () {
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      filtros: {
        handler () {
          this.loadData();
        },
        deep: true
      },
      loading: {
        handler() {
          if (this.loading) {
            this.$refs.perCtrl.$refs.PerFindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    mounted() {
      this.filtros.Per = this.per;
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DOC_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            FechaDesde: this.filtros.FechaDesde,
            FechaHasta: this.filtros.FechaHasta,
            EmpId: this.empId,
            DirId: this.dirId,
            PerId: this.filtros.Per != null ? this.filtros.Per.PerId : null
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/doc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false
                resolve({
                items,
                total,
              })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar los pedidos");
            }
          });
        })
      },
      loadData(){
        this.getDataFromApi()
        .then(data => {
          this.dataRows = []
          data.items.forEach(itemData => {
            var DocEnviado = false;
            if (itemData.DocEnv.length > 0) {
              DocEnviado = true;
            }
            var docData = { DocId: itemData.DocId, DocFec: itemData.DocFec, FecFin: itemData.FecFin, Per: itemData.Per, Enviado: DocEnviado}
            this.dataRows.push(docData);
          });

          // this.dataRows = data.items
          this.totalDataRows = data.total
        });
      },
      onClickRowEditarPedido(item){
        this.$router.push('/pedido-venta-editar/' + item.DocId);
      },
      formatDate (value) {
        if(value == null) return null;
        const date = new Date(value);
        return String(date.getDate()).padStart(2, "0") + '/' + String(date.getMonth() + 1).padStart(2, "0")  + '/' + date.getFullYear();
      },
    } 
})
</script>
